import React, {ForwardedRef, forwardRef, ReactNode, useEffect, useImperativeHandle, useState} from "react";
import './modal.style.css'
import ModalPortal, {ModalPortalOptions} from './ModalPortal';

export enum Position {
  middle,
  rightBottom,
}

export type ModalOptions = {
  children: ReactNode,
  delay?: number,
  closeOnOverlay?: boolean,
  closeOnEsc?: boolean,
  position: Position,
  overlayClass?: string,
  initialVisible?: boolean,
}

export interface ModalRef {
  openModal: () => void;
  closeModal: () => void;
  display: boolean;
}

const Modal = forwardRef((props: ModalPortalOptions<ModalOptions>, ref: ForwardedRef<ModalRef>) => {

  const [display, setDisplay] = useState(props.options.initialVisible ?? false)

  useImperativeHandle(
    ref,
    () => {
      return {
        openModal: () => handleOpen(),
        closeModal: () => handleClose(),
        display: display,
      } as ModalRef
    }
  )

  const handleOpen = () => {
    setDisplay(true);
  }

  const handleClose = () => {
    setDisplay(false);
  }

  useEffect(() => {
    document.addEventListener('keydown', escHandler);
    return () => document.removeEventListener('keydown', escHandler);
  }, []);

  const escHandler = (event: KeyboardEvent) => {
    if (!props.options?.closeOnEsc) {
      return;
    }

    if (event.key === 'Escape') {
      handleClose();
    }
  }

  const overlayClicked = () => {
    if (!props.options?.closeOnOverlay) {
      return;
    }

    document.removeEventListener('keydown', escHandler);
    handleClose();
  }

  if (display) {
    return (
      <ModalPortal options={props.options}>
        <div onClick={overlayClicked}
             className={(props.options.overlayClass || 'overlay fixed top-0 left-0 bottom-0 right-0 h-full backdrop-blur-sm z-[70]')}/>
        {props.options.position === Position.middle &&
          <div className={'fixed bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 z-[70]'}>
            {props.children}
          </div>
        }
        {props.options.position === Position.rightBottom &&
          <div className={'fixed bottom-0 right-0 md:bottom-7 md:right-7 z-[70]'}>
            {props.children}
          </div>
        }
      </ModalPortal>
    )
  }

  return null
});

export default Modal;
