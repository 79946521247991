import {Component, ReactNode} from 'react'
import ReactDOM from 'react-dom'

const portalRoot = typeof document !== `undefined` ? document.getElementById('modal') : null

export type ModalPortalOptions<T> = {
  children: ReactNode,
  options: T
}

class ModalPortal extends Component<ModalPortalOptions<any>> {

  private readonly el: HTMLDivElement | null;

  constructor(props: ModalPortalOptions<any>) {
    super(props)
    this.el = typeof document !== `undefined` ? document.createElement('div') : null
  }

  componentDidMount = () => {
    if (!this.el) {
      return;
    }

    portalRoot?.appendChild(this.el)
  }

  componentWillUnmount = () => {
    if (!this.el) {
      return;
    }

    portalRoot?.removeChild(this.el)
  }

  render() {
    const {children} = this.props

    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    }

    return null
  }
}

export default ModalPortal;
